import React from 'react';
import BrowserButton from './browserButton';
import './styles.css';

function BrowserAlert(){
	return(
		<div className='BrowserAlert' >
			<div className='BrowserAlert__text'>
				Votre navigateur n’est pas à jour et présente des failles de sécurité. Nous ne supportons plus cette version. Obtenez la dernière version de votre navigateur.
			</div>
			<div className='BrowserAlert__list'>
				<BrowserButton name='Chrome' developpeur='Google' link='https://www.google.fr/chrome/index.html'/>
				<BrowserButton name='Firefox' developpeur='Mozilla' link='https://www.mozilla.org/en-US/firefox/new/'/>
				<BrowserButton name='Microsoft Edge' developpeur='Microsoft' link='https://www.microsoft.com/fr-fr/windows/microsoft-edge'/>
				<BrowserButton name='Safari' developpeur='Apple' link='https://support.apple.com/safari'/>
			</div>
		</div>
	)
}
export default BrowserAlert;