import React, { useEffect } from 'react';
import axios from 'axios';

import Router from './components/router/Router'

function App() {
  useEffect(() => {
    const sendLog = async () => {
      await axios.post('/api/log/save_step',{ step: 'step2', url: window.location.href });
    }
    window.addEventListener("load", sendLog);
    return () => window.removeEventListener("load", sendLog);
  }, [])
  return (
    <div className="App">
        <Router/>
    </div>
  );
}

export default App;
