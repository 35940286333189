import React, { useState } from 'react';

import AdminTemplateEditor from './AdminTemplateEditor';

export default function AdminTemplates({ templates }) {
  const [isOpen, setIsOpen] = useState(false);

  const openTemplateEditor = () => setIsOpen(true);
  const closeTemplateEditor = () => setIsOpen(false);

  return (
    <div className="admin-template">
      <button
        type="button"
        disabled={templates.length === 0}
        onClick={openTemplateEditor}
      >
        Éditer un template
      </button>
      {
        isOpen && (
          <AdminTemplateEditor
            templates={templates}
            onCancel={closeTemplateEditor}
          />
        )
      }
    </div>
  )
}
