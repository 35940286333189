/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import styles from './Dropdown.module.css';
import stylesInput from '../style/Input.module.css';

function useOutsideClickAlert(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function Dropdown({
  label, options, /* disabled, */ onChange, selected, required = false, state, hint
}) {
  const { t } = useTranslation();
  const refWrapper = useRef(null);

  const [optionSelected, setOptionSelected] = useState((selected !== undefined) ? selected : '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelection = (newOptionValue, newOptionLabel, key) => {
    onChange(newOptionValue);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    setOptionSelected(((selected !== undefined) && (selected !== -1)) ? selected : '');
  }, [selected, options]);

  useOutsideClickAlert(refWrapper, () => {
    setIsDropdownOpen(false);
  });

  return (
    <div
      className={`${styles.dropdown} ${(state === 'ERROR') ? styles.error : ''}`}
      ref={refWrapper}
    >
      <p className={stylesInput.label}>
        {(required) && (<span className={styles.required}> * </span>)}
        {(typeof label === 'string') && label}
      </p>
      <div
        className={`${(state === 'ERROR') ? stylesInput.error : ''} ${(!isDropdownOpen) ? `${stylesInput.input}` : `${stylesInput.input} ${stylesInput.inputActive}`}`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className={`${styles.inputOption} ${(optionSelected === '') && styles.placeholder}`}>
          {
            (optionSelected === '')
              ? '' : (typeof optionSelected === 'string') && optionSelected
          }
        </div>
        <FontAwesomeIcon
          className={`${styles.icon} ${(isDropdownOpen) && styles.iconAnimate}`}
          icon={(!isDropdownOpen) ? faChevronDown : faChevronUp}
        />
      </div>
      <div className={styles.optionContainer} hidden={!isDropdownOpen}>
        {
          options.map((opt, key) => (
            <div
              role="button"
              tabIndex={-1}
              key={opt.value}
              className={styles.options}
              onClick={() => handleSelection(opt.value, opt.label, key)}
              onKeyDown={(e) => (e.key === 'Enter') && handleSelection(opt.value, opt.label, key)}
            >
              {opt.label}
            </div>
          ))
        }
      </div>
      <p className={styles.hint}>{hint}</p>
    </div>
  );
}
