import React, { useState } from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faGlobe} from '@fortawesome/free-solid-svg-icons';

import './TimezoneDropdown.css';
import timezones from '../../utils/timezone/list';
import TimezoneDropdownSearchbar from './TimezoneDropdownSearchbar/index';

export default function TimezoneDropdown({ FormattedMessage, value, onChange, disabled }) {
  const [isOpen, setIsOpen] = useState(false);
  const getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone || undefined;
  const options = { hour12: false, hour: '2-digit', minute:'2-digit' };
  let optionsWithValue = options;
  optionsWithValue['timeZone'] = value;
  if (!getCurrentTimezone() && !value) return null;
  return (
    <div className="timezone__container">
      <button
        type="button"
        className="timezone__button"
        onClick={() => setIsOpen((state) => !state)}
        disabled={disabled}
      >
        <span>
          <FontAwesomeIcon
            icon={faGlobe}
            className="timezone__icon"
          />
        <FormattedMessage id="timezone.label">
          {
            (message) => (
              <span>
              {`${message} : ${value.replace('_', ' ') ||getCurrentTimezone().replace('_', ' ')} (${new Date().toLocaleTimeString(undefined, (value) ? optionsWithValue : options)})`}
              </span>
            )
          }
        </FormattedMessage>
        </span>
        {
          (!disabled)
          && (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="timezone__dropdown"
            />
          )
        }
      </button>
      {
        (isOpen)
        && (
          <div className="timezone__list">
            <TimezoneDropdownSearchbar>
              {
                (query) => (
                  <div className="timezone__country">
                    {
                      timezones
                        .filter((timezone) => timezone
                          .replace('_',' ')
                          .toLowerCase()
                          .includes(query.toLowerCase()))
                        .map((timezone) => (
                          <button
                            type="button"
                            className="timezone__city"
                            onClick={() => {
                              onChange(timezone);
                              setIsOpen(false);
                            }}
                          >
                            { timezone }
                          </button>
                        ))
                    }
                  </div>
                )
              }
            </TimezoneDropdownSearchbar>
          </div>
        )
      }
    </div>
  );
}
