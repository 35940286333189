import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Button from '../../Button';
import { Modal } from 'react-bootstrap';
import Dropdown from '../../Dropdown/Dropdown';

function TransferModalOneAppointment({users, selectedUser, appointmentId,
                                       setAppointmentId,
                                       removeAppointmentById}) {
  const [userToTransfer, setUserToTransfer] = useState('');

  const closeModal = () => setAppointmentId('');

  async function transferAppointment() {
    if (userToTransfer === '') {
      return false;
    }
    try {
      let response = await axios.request({
        method: 'POST',
        url: '/api/move-appointment-by-id',
        ContentType: 'application/json',
        data: {
          appointment_id: appointmentId,
          user: userToTransfer
        }
      });
      removeAppointmentById(appointmentId);
      setAppointmentId('')
    } catch (err) {
      console.log(`Error in transferAppointment: ${err}`);
    }
  }

  return (
    <Modal show={appointmentId !== ''} onHide={closeModal}>
      <Modal.Header closeButton>
        {`Transfert du/des rendez-vous de ${selectedUser}`}
      </Modal.Header>
      <Modal.Body>
        <Dropdown options={users} onChange={setUserToTransfer}
                  selected={userToTransfer}/>
        <Button onClick={() => transferAppointment()}
                disabled={userToTransfer === '' || userToTransfer === selectedUser}>
          Transférer
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default TransferModalOneAppointment;
