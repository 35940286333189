const getTimezoneOffsetFromName = (timezoneName) => {
  const options = { timeZone: timezoneName, timeZoneName: 'shortOffset' };
  try {
    const date = Intl.DateTimeFormat([], options).format(new Date);

    const timeZone = date.split(' ')[1]?.slice(3) || '+00:00';
    const offsetAsString = timeZone.split(':')[0].replace('−', '-');
    const offsetAsInt = Number.parseInt(offsetAsString, 10);
    let offset = (!Number.isNaN(offsetAsInt) ? offsetAsInt : 0) * 60;
    if (timeZone.includes(':')) {
      offset = offset + Number.parseInt(timeZone.split(':')[1]);
    }
    if (Number.isNaN(offset)) {
      throw new Error('Offset is NaN');
    }
    return offset;
  } catch (err) {
    console.error('shortOffset option not supported');
    return null;
  }
};

export default getTimezoneOffsetFromName;
