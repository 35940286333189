import React from 'react';
import Button from '../../components/Button'
import { Modal } from 'react-bootstrap';

function AppointmentModal({selectedAppointment, setSelectedAppointment,
                            setIdToTransfer}) {
  const closeModal = () => setSelectedAppointment({});

  return (
    <Modal show={Object.keys(selectedAppointment).length !== 0}
           onHide={closeModal}>
      <Modal.Header closeButton >
        {`Rendez-vous du ${selectedAppointment.date}`}
      </Modal.Header>
      <Modal.Body>
        <Button onClick={() => setIdToTransfer(selectedAppointment.id)}>
          Transférer ce rendez-vous
        </Button>
        <h1>
          {`${selectedAppointment.firstName} ${selectedAppointment.lastName}`}
        </h1>
        <h2>{selectedAppointment.society}</h2>
        <h3>{selectedAppointment.email}</h3>
        <h3>{selectedAppointment.phoneNumber}</h3>
        <h5>{`Rendez-vous pris le ${selectedAppointment.created}`}</h5>
        <p>{`From: ${selectedAppointment.from}`}</p>
        <p>{`Source: ${selectedAppointment.source}`}</p>
      </Modal.Body>
    </Modal>
  );
}

export default AppointmentModal;
