const fr = {
    'timezone.label': 'Fuseau horaire',
    'calendly.chooseDay': 'Sélectionnez une date',
    'calendly.chooseTime': 'Sélectionnez un horaire',
    'calendly.callDuration': 'Durée de l\'appel ',
    'calendly.chooseDate': 'Merci de choisir une date ci-dessus !',
    'calendly.noTimeAvailable': 'Désolé, il n\'y a plus de rendez-vous disponible à cette date.',
    'popup.selected': 'Vous avez selectionné',
    'popup.firstname': 'Prénom',
    'popup.lastname': 'Nom de famille',
    'popup.mail': 'Adresse e-mail',
    'popup.phone': 'Téléphone',
    'popup.validate': 'Valider le rendez-vous téléphonique',
    'popup.comInfo': 'Merci de nous communiquer vos coordonnées afin que nous puissions vous contacter',
    'popup.textWait': 'Patientez, prise de rdv en cours...',
    'popup.booked.title': 'Nous sommes désolés ce rendez-vous n\'est plus disponible.',
    'popup.booked.description': 'Ce créneau a été réservé par un autre utilisateur à l\'instant',
    'popup.booked.button': 'Choisir un autre créneau',
    'popup.lp.title': 'Accès au deck',
    'popup.lp.description': 'MERCI pour votre marque d\'intérêt pour notre entreprise !{br}Nous avons identifié des concurrents parmi les demandes d\'informations.{br}Nous aimerions confirmer votre intention via un appel téléphonique d\'une minute avant de vous transmettre les éléments. J\'espère que vous comprenez.{br}{br}Vous trouverez mon agenda ci-après.',
    'popup.lp.button': 'Je choisis mon moment',
    'popup.privacy': 'J’accepte d’être contacté par téléphone ou email',
    'calendar.mon': 'LUN',
    'calendar.tue': 'MAR',
    'calendar.wed': 'MER',
    'calendar.thu': 'JEU',
    'calendar.fri': 'VEN',
    'calendar.sat': 'SAM',
    'calendar.sun': 'DIM',
    'pop_error.error': 'Ce calendrier n\'existe pas',
    'pop_loading.txt': 'Chargement ...',
    'pop_loading.title': 'Recherche de créneaux libres en cours ...',
    'pop_loading.description': 'Le chargement des créneaux peut prendre un moment, nous vous remercions pour votre patience.',
    'pop_loading.hint': 'Choisissez (1) la date, (2) l\'horaire, (3) finalisez',
    'pop_confirmation.txt': 'Vous avez pris un rendez-vous téléphonique',
    'pop_confirmation.title': 'Confirmation',
    'pop_confirmation.description': 'Votre rendez-vous a bien été planifié',
    'pop_confirmation.timezone': 'Fuseau horaire',
    'pop_confirmation.visit': 'Visiter notre site internet',
    'pop_confirmation.back': 'Retour au calendrier',
    'pop_confirmation_media.video': 'En attendant votre rendez-vous, découvrez Ayomi :',
    'pop_confirmation_media.press': 'La presse parle de nous :',
    'dashboard.choose': 'Sélectionnez',
    'dashboard.date': 'La date',
    'dashboard.time': 'L\'horaire',
    'dashboard.final': 'Finalisez la prise de rendez-vous'
};
export default fr;
