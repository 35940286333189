
import React from 'react';
import routes from './routes'



export default class Router extends React.Component
{
    render() {
        let hash = window.location.hash
        hash = hash.replace("#", "")
        let listHash = hash.split('/')
        if (listHash[1] === 'calendrier'){
            hash = '/calendrier'
        }
        if (listHash[1] === 'admin') {
            hash = '/admin'
        }

        let CT =  routes[hash] || routes["/"];
        return <CT/>
    }
}
