import React from 'react';

export default function AdminTemplateEditorView({ selectedTemplate }) {
  if (!selectedTemplate) return <div />;
  return (
    <div className="template-editor-view">
      <p className="template-editor-type">{selectedTemplate.template}</p>
      <h1>{selectedTemplate.title}</h1>
      <p>{selectedTemplate.preface}</p>
      <p>
        <span>Heure de début : </span>
        <span>{selectedTemplate.start_time}</span>
      </p>
      <p>
        <span>Heure de fin : </span>
        <span>{selectedTemplate.last_time}</span>
      </p>
      <p>
        <span>Durée : </span>
        <span>{selectedTemplate.time}</span>
      </p>
      <p>Details : </p>
      <pre className="template-editor-code">
        {JSON.stringify(selectedTemplate.details, null, 2)}
      </pre>
    </div>
  )
}
