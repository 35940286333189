/* eslint-disable no-unused-vars */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import getQueryStringParams from '../../utils/getQueryStringParams';

import Loading from "../../components/Loading";

export default class pop_loading extends React.Component  {
    constructor(props) {
        super(props)
        this.togglePopErr = this.togglePopErr.bind(this)
    }
    togglePopErr() {
        this.setState({
            poperr: !this.state.poperr
        });
    }

    pad(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }
    render()
    {
      return (true)
        ? (
        <div hidden = {this.props.hidden}>
          <div style = {{opacity : 0.8}} className="scrim"></div>
          <div className="modal-wrap">
            <Loading />
            <FormattedMessage id="pop_loading.title">
              {
                (message) => (
                  <p className="loading-title">{message}</p>
                )
              }
            </FormattedMessage>
            <FormattedMessage id="pop_loading.description">
              {
                (message) => (
                  <p className="loading-description">
                    {message}
                  </p>
                )
              }
            </FormattedMessage>
            <FormattedMessage id="pop_loading.hint">
              {
                (message) => (
                  <p className="loading-hint">
                    {message}
                  </p>
                )
              }
            </FormattedMessage>
            <div className="loading-row">
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  className="loading-icon"
                />
                <span>
                  +33 (0)7 55 50 63 90
                </span>
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="loading-icon"
                />
                <span>
                  plateforme@ayomi.fr
                </span>
              </p>
            </div>

          </div>
        </div>
       )
      : (
       (
        <div hidden = {this.props.hidden}>
          <div style = {{opacity : 0.8}} className="scrim"></div>
          <div className="modal-wrap">
            <FormattedMessage id="pop_loading.txt">
              {(messages) => <header className="header">{messages}</header>}
            </FormattedMessage>

          </div>
        </div>
      )
    )
    }
}