import React from 'react';
import Chrome from './images/chrome.png';
import Firefox from './images/Firefox.png';
import Safari from './images/safari.jpg';
import Edge from './images/Edge.jpg';
import './styles.css';

function BrowserButton(props){
	var imageName='';
	if (props.name==='Chrome') imageName=Chrome;
	if (props.name==='Firefox') imageName=Firefox;
	if (props.name==='Safari') imageName=Safari;
	if (props.name==='Microsoft Edge') imageName= Edge;

	return(
		<a className='BrowserButton' target='_blank' rel="noopener noreferrer" href={props.link}>
			<div className='BrowserButton__img'>
				<img src={imageName} alt="" />
			</div>
			<div className='BrowserButton__text'>
				<p>{props.name}</p>
				<p>{'by '+props.developpeur}</p>
			</div>
		</a>
	)
}
export default BrowserButton;