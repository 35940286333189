/* eslint-disable no-unused-vars */
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import './style.css'


export default class Calendar extends React.Component {
    constructor(props)
    {
        super(props)

        this.monthList = {
            0: "JANVIER",
            1: "FEVRIER",
            2: "MARS",
            3: "AVRIL",
            4: "MAI",
            5: "JUIN",
            6: "JUILLET",
            7: "AOUT",
            8: "SEPTEMBRE",
            9: "OCTOBRE",
            10: "NOVEMBRE",
            11: "DECEMBRE",
        }

        this.enMonthList = {
            0: "JANUARY",
            1: "FEBRUARY",
            2: "MARCH",
            3: "APRIL",
            4: "MAY",
            5: "JUNE",
            6: "JULY",
            7: "AUGUST",
            8: "SEPTEMBER",
            9: "OCTOBER",
            10: "NOVEMBER",
            11: "DECEMBER",
        }

        this.state = {
            currentMonth: props.currentMonth,
            currentYear: props.currentYear,
            currentDate : [],
            prevDisable : false,
            dateAvailable : [],
            template : {}
        }

        this.stDate = this.stDate.bind(this)

        this.prev = this.prev.bind(this)
        this.next = this.next.bind(this)
        this.select = this.select.bind(this)
        this.componentDidMount = this.componentDidMount.bind(this)
        this.checkLanguage = this.checkLanguage.bind(this)

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.currentMonth !== nextProps.currentMonth
            || prevState.currentYear !== nextProps.currentYear
            || prevState.dateAvailable !== nextProps.dateAvailable) {
            return ({ currentMonth: nextProps.currentMonth,
                currentYear: nextProps.currentYear,
                dateAvailable: nextProps.dateAvailable });
        }
        return null;
    }

    componentDidMount()
    {
        this.setState({dateAvailable:this.props.dateAvailable})
    }

    pad(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    select(e, date, select=true)
    {
        if (select === true)
        {
            this.setState({currentDate : date})
            this.props.onSelect([date[0], date[1] , date[2]])
        }

    }

    setDayAtMonday(d)
    {
        let day
        if (d === 0)
            day = 7
        else if (d === 7)
            day = 0
        else
            day = d
        return day
    }

    stDate(d)
    {
        const { currentMonth, currentYear } = this.state;
        let current = new Date();
        let dateSelect = new Date(d[2], d[1], d[0])
        let css_style = "calendly__calendar__date_available"

        if ( d[2] < current.getFullYear())
            css_style = "calendly__calendar__date_unavailable"
        if ( d[1] < current.getMonth() && current.getFullYear() >= this.state.currentYear)
            css_style = "calendly__calendar__date_unavailable"
        if (d[0] < current.getDate() && d[1] === current.getMonth() &&  current.getFullYear() === this.state.currentYear)
            css_style = "calendly__calendar__date_unavailable"

        if (d[0]  === this.state.currentDate[0] && d[1]  === this.state.currentDate[1] && d[2]  === this.state.currentDate[2])
            css_style = "calendly__calendar__date_clicked"


        if (!this.state.dateAvailable.includes(d[0]))
        {
            css_style = "calendly__calendar__date_unavailable"
        }

        if (d[2] > this.state.currentYear || d[2] < this.state.currentYear || d[1] > this.state.currentMonth ||  d[1] < this.state.currentMonth)
        {
            return (
                <td key={d[0] + "/" + this.state.currentMonth + "/" +this.state.currentYear}>
                    <div  hidden={true} onClick={(e) => {this.select(e, d)}} className={css_style}>{d[0]}</div>
                </td>
            )
        }
        return (
            <td key={d[0] + "/" + this.state.currentMonth + "/" +this.state.currentYear}>
                <div onClick={(e) => {this.select(e, d, (css_style === "calendly__calendar__date_unavailable" ) ? false : true )}} className={css_style}>{d[0]}</div>
            </td>
        )
    }

    generateCalendar(month, year)
    {
        let calendar = []
        let lastMonthLastDay = new Date(year,month, 0);
        let lastDay = new Date(year,month + 1, 0);
        let correctif = 0

        if (this.setDayAtMonday(lastMonthLastDay.getDay()) -1 === 0)
            correctif = 0
        else
            correctif = this.setDayAtMonday(lastMonthLastDay.getDay()) -1

        let date  = lastMonthLastDay.getDate() - correctif

        if (this.setDayAtMonday(lastMonthLastDay.getDay()) -1 !== 6)
        {
            while (date < lastMonthLastDay.getDate() + 1)
            {
                calendar.push([date, this.state.currentMonth -1, this.state.currentYear])
                date++
            }
        }

        date = 1
        while (date < lastDay.getDate() + 1)
        {
            calendar.push([date, this.state.currentMonth, this.state.currentYear])
            date++
        }
        date = 1
        while (calendar.length < 42)
        {
            calendar.push([date, this.state.currentMonth + 1, this.state.currentYear])
            date++
        }

        let l1 = calendar.slice(0,7).map((d) => {
            return this.stDate(d)
        })
        let l2 = calendar.slice(7,14).map((d) => {
            return this.stDate(d)
        })
        let l3 = calendar.slice(14,21).map((d) => {
            return this.stDate(d)
        })
        let l4 = calendar.slice(21,28).map((d) => {
            return this.stDate(d)
        })
        let l5 = calendar.slice(28,35).map((d) => {
            return this.stDate(d)
        })
        let l6 = calendar.slice(35,42).map((d) => {
            return this.stDate(d)
        })
        return [l1,l2,l3,l4,l5, l6]
    }

    next()
    {
        let year = this.state.currentYear
        let nextMonth = this.state.currentMonth + 1

        if (nextMonth > 11)
        {
            year++
            nextMonth = 0
        }
        this.setState({ prevDisable: false });
        this.props.onSelect([0, nextMonth, year])
    }

    prev()
    {
        let current = new Date();
        let year = this.state.currentYear
        let prevMonth = this.state.currentMonth - 1

        if (prevMonth < 0)
        {
            year--
            prevMonth = 11
        }

        if (current.getMonth() > prevMonth - 1 && current.getFullYear() >= this.state.currentYear)
        {
            this.setState({prevDisable: true})
        }
        this.props.onSelect([0, prevMonth, year])
    }

    checkLanguage()
    {
        let language = 'fr'
        switch (navigator.language.split('-')[0]) {
            case 'en':
                language = "en";
                break;
            default:
                language = 'fr'
        }
        if (language === 'en') {
            return (this.enMonthList[this.state.currentMonth])
        }
        return (this.monthList[this.state.currentMonth])
    }

    render()
    {
        let calendar = this.generateCalendar(this.state.currentMonth, this.state.currentYear)

        return (
            <div style={{width:"100%"}}>
                <table style={{width:"100%"}}>
                <tbody>
                    <tr>
                        <th style={{width:"10%"}}>
                            <Button
                              disabled={this.state.prevDisable}
                              onClick={(e) => {this.prev()}}
                              className="active-button"
                              size="sm"
                              aria-label="Previous date"
                            >
                                <span style={{width:"15px"}} aria-hidden="true" className="carousel-control-prev-icon" />
                            </Button>
                        </th>
                        <th style={{width:"20%"}} />
                        <th style={{width:"40%"}}>{this.checkLanguage()}{' '}{this.state.currentYear}</th>
                        <th style={{width:"20%"}} />
                        <th style={{width:"10%"}}>
                            <Button
                              onClick={(e) => {this.next()}}
                              className="active-button"
                              size="sm"
                              aria-label="Next date"
                            >
                                <span style={{width:"15px"}} aria-hidden="true" className="carousel-control-next-icon" />
                            </Button>
                        </th>
                    </tr>
                    </tbody>
                </table>
                <table style={{width:"100%"}}>
                <tbody>
                    <tr>
                        <FormattedMessage id="calendar.mon">
                            {(messages) => <td>{messages}</td>}
                        </FormattedMessage>
                        <FormattedMessage id="calendar.tue">
                            {(messages) => <td>{messages}</td>}
                        </FormattedMessage>
                        <FormattedMessage id="calendar.wed">
                            {(messages) => <td>{messages}</td>}
                        </FormattedMessage>
                        <FormattedMessage id="calendar.thu">
                            {(messages) => <td>{messages}</td>}
                        </FormattedMessage>
                        <FormattedMessage id="calendar.fri">
                            {(messages) => <td>{messages}</td>}
                        </FormattedMessage>
                        <FormattedMessage id="calendar.sat">
                            {(messages) => <td>{messages}</td>}
                        </FormattedMessage>
                        <FormattedMessage id="calendar.sun">
                            {(messages) => <td>{messages}</td>}
                        </FormattedMessage>
                    </tr>
                    <tr>
                        {calendar[0]}
                    </tr>
                    <tr>
                        {calendar[1]}
                    </tr>
                    <tr>
                        {calendar[2]}
                    </tr>
                    <tr>
                        {calendar[3]}
                    </tr>
                    <tr>
                        {calendar[4]}
                    </tr>
                    <tr>
                        {calendar[5]}
                    </tr>
                    </tbody>
                </table>
            </div>

        )
    }
}
