import React, {useEffect, useState} from 'react';
import AppointmentPreview from './AppointmentPreview';
import './style.css';

function AppointmentsList({appointments, setSelectedAppointment}) {
  function selectAppointment(appointment) {
    setSelectedAppointment(appointment);
  }

  return (
    <div className='appointment-list-container'>
      {
        appointments.map((appointment) => {
          return (
            <AppointmentPreview
              date={appointment.date}
              fullName={`${appointment.firstName} ${appointment.lastName}`}
              society={appointment.society}
              setSelectedAppointment={() => setSelectedAppointment(appointment)}
              key={`${appointment.date}-${appointment.created}`}
            />)
        })
      }
    </div>
  )
}

export default AppointmentsList;
