import fr from './translations/fr';
import en_US from './translations/en_US';

export const lang = getLanguage();

export function getLanguage() {
    let language = fr;
    switch (navigator.language.split('-')[0]) {
        case 'en':
            language = en_US;
            break;
        case 'fr':
            language = fr;
            break;
        default:
            language = en_US;
    }
    return language
}