import React from 'react';
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './rdv-calend.css'
import PopupModif from './popupModif'

export default class calendrierUser extends React.Component  {
    constructor(props) {
        super(props)
        this.state = {
            user: "",
            template: "",
            events : [],
            oldEvents: [],
            saveDate : "",
            dateArr : {},
            oldDateArr: {},
            eventToModify : {},
            toCome: false,
            past: true,
            popModify: true,
            idArr: [],
            oldIdArr: [],
            doneObj: {}
        }
        this.getAllEvents = this.getAllEvents.bind(this)
        this.dispEvents = this.dispEvents.bind(this)
        this.dispSpecialField = this.dispSpecialField.bind(this)
        this.getDifDate = this.getDifDate.bind(this)
        this.dispDay = this.dispDay.bind(this)
        this.getOldDifDate = this.getOldDifDate.bind(this)
        this.deleteEvent = this.deleteEvent.bind(this)
        this.callApiDel = this.callApiDel.bind(this)
        this.confirmSchedule = this.confirmSchedule.bind(this)
        this.dispToCome = this.dispToCome.bind(this)
        this.dispPast = this.dispPast.bind(this)
        this.closeModif = this.closeModif.bind(this)
        this.initDoneObj = this.initDoneObj.bind(this)
        this.changeDone = this.changeDone.bind(this)
    }

    componentDidMount()
    {
        this.getAllEvents()
    }

    initDoneObj() {
        let objDone = {}

        this.state.events.forEach((element) => {
            let tmpDate = element['_source']['date']
            let listDateTime = tmpDate.split(' ')
            let listDate = listDateTime[0].split('-')
            let listTime = listDateTime[1].split(':')
            let date = listDate[2] + '/' + listDate[1] + '/' + listDate[0] + ' ' + listTime[0] + 'h' + listTime[1]

            objDone[date + ' ' + element['_source']['lastName']] = element['_source']['done']
        })
        this.state.oldEvents.forEach((element) => {
            let tmpDate = element['_source']['date']
            let listDateTime = tmpDate.split(' ')
            let listDate = listDateTime[0].split('-')
            let listTime = listDateTime[1].split(':')
            let date = listDate[2] + '/' + listDate[1] + '/' + listDate[0] + ' ' + listTime[0] + 'h' + listTime[1]

            objDone[date + ' ' + element['_source']['lastName']] = element['_source']['done']
        })

        this.setState({
            doneObj: objDone
        })
    }

    getAllEvents() {
        let full_url = window.location.href
        let list = full_url.split('/')

        if (list[6]) {
            this.setState({
                user : list[6],
                template : list[5]
            })
        }

        axios.request({
            method: 'POST',
            url: '/api/get_event',
            data: {
                "user" : list[6],
                "template" : list[5]
            },
            ContentType : "application/json; charset=utf-8"
          
        }).then((response) => {
            this.setState({
                events : response['data']['hits']['hits']
            })
            this.getDifDate()
            axios.request({
                method: 'POST',
                url: '/api/get_old_event',
                data: {
                    "user" : list[6],
                    "template" : list[5]
                },
                ContentType : "application/json; charset=utf-8"
              
            }).then((response) => {
                this.setState({
                    oldEvents : response['data']['hits']['hits']
                })
                this.getOldDifDate()
                this.initDoneObj()
            })
        })
    }

    getDifDate() {
        let difDate = {}

        this.state.events.forEach((element) => {
            let tmpDate = element['_source']['date']
            let currentDate = tmpDate.split(' ')[0]
            if (difDate[currentDate] === undefined){
                difDate[currentDate] = []
            }
            difDate[currentDate].push(element)
        });
        this.setState({
            dateArr : difDate
        })
    }

    getOldDifDate() {
        let difDate = {}

        this.state.oldEvents.forEach((element) => {
            let tmpDate = element['_source']['date']
            let currentDate = tmpDate.split(' ')[0]
            if (difDate[currentDate] === undefined){
                difDate[currentDate] = []
            }
            difDate[currentDate].push(element)
        });
        this.setState({
            oldDateArr : difDate
        })
    }


    dispSpecialField(infos) {
        let keyName = ""
        let fieldName = ""

        for (var key in infos['details']) {
            if (key !== 'société') {
                keyName = key + ': '
                fieldName = infos['details'][key]
                return(
                    <div>
                        <p>{keyName}</p>
                        <span>{fieldName}</span>
                    </div>
                )
            }
        }
    }

    callApiDel(event) {
        let details = {"société": event['societe']}
        axios.request({
            method: 'POST',
            url: '/api/delete_event',
            data: {
                "date" : event['realDate'],
                "template" : this.state.template,
                "user" : this.state.user,
                "firstName" : event['firstName'],
                "lastName" : event['lastName'],
                "email" : event['email'],
                "phone" : event['phone'],
                "details" : details
            },
            ContentType : "application/json; charset=utf-8"
        }).then((response) => {
            if (response['data']['deleted'] === 1)
                alert("Rdv supprimé avec succès")
            else
                alert("Le rdv n'a pas pu être supprimé.\nRechargez la page, il a peut-être déjà été supprimé")
        })
    }

    deleteEvent(event) {
        confirmAlert({
            title: 'Êtes-vous sûr de vouloir supprimer ce rdv ?',
            message: 'Infos du rdv: ' + event['date'] + ', ' + event['firstName'] + ' ' + event['lastName'] + ', Société: ' + event['societe'],
            buttons: [
              {
                label: 'Oui',
                onClick: () => this.callApiDel(event)
              },
              {
                label: 'Non'
              }
            ]
        })
    }

    confirmSchedule(event) {
        confirmAlert({
            title: 'Êtes-vous sûr de vouloir replanifier ce rdv ?',
            message: 'Infos du rdv: ' + event['date'] + ', ' + event['firstName'] + ' ' + event['lastName'] + ', Société: ' + event['societe'],
            buttons: [
              {
                label: 'Oui',
                onClick: () => this.reScheduleEvent(event)
              },
              {
                label: 'Non'
              }
            ]
        })
    }

    reScheduleEvent(event) {
        let details = {"société": event['societe']}
        axios.request({
            method: 'POST',
            url: '/api/delete_event',
            data: {
                "date" : event['realDate'],
                "template" : this.state.template,
                "user" : this.state.user,
                "firstName" : event['firstName'],
                "lastName" : event['lastName'],
                "email" : event['email'],
                "phone" : event['phone'],
                "details" : details
            },
            ContentType : "application/json; charset=utf-8"
        }).then((response) => {
            if (response['data']['deleted'] === 1) {
                let strInfos = "?name=" + event['firstName'] + "&surname=" +event['lastName'] +"&email=" + event['email'] + "&phone=" + event['phone'] + "&societe=" + event['societe'] + "&redirect=1"
                let url = "https://calendrier.ayomi.fr/#/" + this.state.template + '/' + this.state.user + strInfos
                window.open(url,'_blank');
            }
            else
                alert("Le rdv ne peut pas être replanifié.\nRechargez la page, il n'existe peut-être pas.")
        })
    }    

    changeDone(event) {
        let newDone = 0
        let objDone = this.state.doneObj

        if (this.state.doneObj[event['date'] + ' ' + event['lastName']] === 0)
            newDone = 1

        objDone[event['date'] + ' ' + event['lastName']] = newDone
        this.setState({
            doneObj: objDone
        })

        axios.request({
            method: 'POST',
            url: '/api/update_event_done',
            data: {
                "template" : event['template'],
                "user" : event['user'],
                "realDate": event['realDate'],
                "lastName" : event['lastName'],
                "newDone" : newDone
            },
            ContentType : "application/json; charset=utf-8"
        })
        // .then((response) => {
        //     setTimeout(() => {this.getAllEvents()}, 500)
        // })
    }

    dispEvents(events) {
        let allEvents = []

        for (let i = 0; events[i]; i++) {
            let tmp = events[i]

            let tmpDate = tmp['_source']['date']
            let listDateTime = tmpDate.split(' ')
            let listDate = listDateTime[0].split('-')
            let listTime = listDateTime[1].split(':')
            let date = listDate[2] + '/' + listDate[1] + '/' + listDate[0] + ' ' + listTime[0] + 'h' + listTime[1]

            let firstName = tmp['_source']['firstName']
            let lastName = tmp['_source']['lastName']
            let email = tmp['_source']['email']
            let phone = tmp['_source']['phone']
            let societe = tmp['_source']['details']['société']
            let details = tmp['_source']['details']
            let done = tmp['_source']['done']
            let template = tmp['_source']['template']
            let user = tmp['_source']['user']

            let tmpCrea = tmp['_source']['dateCreation']
            let listCreaTime = tmpCrea.split(' ')
            let listCrea = listCreaTime[0].split('-')
            let creaTime = listCreaTime[1].split(':')
            let dateCrea = listCrea[2] + '/' + listCrea[1] + '/' + listCrea[0] + ' ' + creaTime[0] + 'h' + creaTime[1]


            allEvents.push({"date" : date, "realDate": tmpDate, "firstName" : firstName, "lastName" : lastName, "email" : email, "phone" : phone, "societe" : societe, "dateCreation" : dateCrea, "details" : details, "done": done, "template" : template, "user": user})
        }

        let listEvent = allEvents.map((i)=> {
            return (
                <div key={i['date']} class="cardCal">
                    <div class="pretty p-default">
                        <input type="checkbox" onChange={(e) => this.changeDone(i)} checked={this.state.doneObj[i['date'] + ' ' + i['lastName']]}/>
                        <div class="state p-primary">
                            <label>Traité</label>
                        </div>
                    </div>
                    <p class="dateCal">{i['date']}</p>
                    <b class="name">{i['firstName']} {i['lastName']}</b>
                    <br />
                    <i class="material-icons">
                        phone
                    </i>
                    <span class="phone">{i['phone']}</span>
                    <br />
                    <i class="material-icons">
                        mail
                    </i>
                    <span class="mail">{i['email']}</span>
                    <p>Société:</p> 
                    <b class="company">{i['societe']}</b>
                    {this.dispSpecialField(i)}
                    <span class="picked">RDV pris le : {i['dateCreation']}</span>
                    <p style={{color:"blue", cursor:"pointer"}} onClick={() => {this.deleteEvent(i)}}>Supprimer le rdv</p>
                    <p style={{color:"blue", cursor:"pointer"}} onClick={() => {this.confirmSchedule(i)}}>Replanifier le rdv</p>
                </div>
            )
        })
        return (listEvent)
    }
    
    dispDay() {
        let display = []
        let saveDate
        let rdv

        for(var e in this.state.dateArr) {
            rdv = this.state.dateArr[e][0]
            saveDate = rdv['_source']['date']
            let listDateTime = saveDate.split(' ')
            let listDate = listDateTime[0].split('-')
            let date = listDate[2] + '/' + listDate[1] + '/' + listDate[0]

            display.push(<h2 key={date} class="intitule">Rendez-vous du {date}</h2>)
            display.push(this.dispEvents(this.state.dateArr[e]))
        }
        return display
    }

    dispOldDay() {
        let display = []
        let saveDate
        let rdv

        for(var e in this.state.oldDateArr) {
            rdv = this.state.oldDateArr[e][0]
            saveDate = rdv['_source']['date']
            let listDateTime = saveDate.split(' ')
            let listDate = listDateTime[0].split('-')
            let date = listDate[2] + '/' + listDate[1] + '/' + listDate[0]

            display.push(<h2 key={date} class="intitule">Rendez-vous du {date}</h2>)
            display.push(this.dispEvents(this.state.oldDateArr[e]))
        }
        return display
    }

    dispToCome() {
        this.setState({
            toCome: false,
            past: true
        })
    }

    dispPast() {
        this.setState({
            toCome: true,
            past: false
        })
    }

    dispModif() {
        this.setState({
            popModify: !this.state.popModify
        })
    }

    closeModif() {
        this.setState({
            popModify: true
        })
    }

    render() {
        return(
            <div key="calendrier">
                <h1>Calendrier du user {this.state.user} du template {this.state.template}</h1>
                <br/>
                <PopupModif hidden={this.state.popModify} user={this.state.user} template={this.state.template} closePop={this.closeModif}/>
                <button onClick={(e) => this.dispToCome()}>RDV à venir</button> <button onClick={(e) => this.dispPast()}>RDV passés</button> <button onClick={(e) => this.dispModif()}>Gérer mes créneaux de rdvs</button>
                <br/>
                <br/>
                <b hidden={this.state.toCome}>RDV à venir:</b>
                <div hidden={this.state.toCome} key="events to come" className="gridCal">
                    {this.dispDay()}
                </div>
                <b hidden={this.state.past}>RDV passés:</b>
                <div hidden={this.state.past} key="old events" className="gridCal">
                    {this.dispOldDay()}
                </div>
            </div>
        )
    }
}