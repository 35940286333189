export const timeToDisplay = (time, offset) => {
  if (!offset || Number.isNaN(offset)) return time;
  if (time && time.split) {
    const [hour, minute] = time.split('h');
    const timeToMinute = Number.parseInt(hour, 10) * 60 + Number.parseInt(minute, 10);
    const newTime = timeToMinute + offset;
    const newHour = Math.floor(newTime/60)%24;
    const newMinutes = newTime%60;
    return `${newHour < 10 ? 0 : ''}${newHour}h${newMinutes}${newMinutes < 10 ? 0 : ''}`;
  }
  return time;
};
