import React from "react";
import './style.css'

function Button({disabled=false, type='primary', onClick,
                  children}) {
  return (
    <div className='ayomi-calendar-button'>
      <button onClick={onClick} disabled={disabled}
              className={`common-button ${(disabled) ? 'disabled' : type}`}>
        {children}
      </button>
    </div>
  )
}

export default Button;
