const en_US = {
    'timezone.label': 'Timezone',
    'calendly.chooseDay': 'Pick a day',
    'calendly.chooseTime': 'Pick a time',
    'calendly.callDuration': 'Duration of the call ',
    'calendly.chooseDate': 'Please choose a date above !',
    'calendly.noTimeAvailable': 'Sorry, there are no more appointments available on this date.',
    'popup.selected': 'You have chosen',
    'popup.firstname': 'First name',
    'popup.lastname': 'Last name',
    'popup.mail': 'Email address',
    'popup.phone': 'Phone',
    'popup.validate': 'Confirm the phone appointment',
    'popup.comInfo': 'Please fill this form so we can contact you',
    'popup.textWait': 'Pease wait, your appointment is being registered...',
    'popup.booked.title': 'We\'re sorry this appointment is no longer available.',
    'popup.booked.description': 'This time slot has been reserved by another user at the moment',
    'popup.booked.button': 'Choose another time slot',
    'popup.lp.title': 'Access to the Deck',
    'popup.lp.description': 'THANK YOU for your interest in our company!{br}We have identified competitors among the information requests.{br}We would like to confirm your intention through a one- minute phone call before sending you the materials. I hope you understand.{br}{br}You will find my schedule below.',
    'popup.lp.button': 'I Choose My Time',
    'popup.privacy': 'I agree to be contacted by phone call or email',
    'calendar.mon': 'MON',
    'calendar.tue': 'TUE',
    'calendar.wed': 'WED',
    'calendar.thu': 'THU',
    'calendar.fri': 'FRI',
    'calendar.sat': 'SAT',
    'calendar.sun': 'SUN',
    'pop_error.error': 'This calendar does not exist',
    'pop_loading.txt': 'Loading ...',
    'pop_confirmation.txt': 'You have registered for a phone appointment',
    'pop_loading.title': 'Free time slot search in progress ...',
    'pop_loading.description': 'Loading slots may take a while, thank you for your patience.',
    'pop_loading.hint': 'Choose (1) the date, (2) the time, (3) finalize',
    'pop_confirmation.title': 'Confirmation',
    'pop_confirmation.description': 'Your meeting has been well planned',
    'pop_confirmation.timezone': 'Timezone',
    'pop_confirmation.visit': 'Visit our website',
    'pop_confirmation.back': 'Back to calendar',
    'pop_confirmation_media.video': 'While waiting for your appointment, discover Ayomi :',
    'pop_confirmation_media.press': 'The press talks about us :',
    'dashboard.choose': 'Select',
    'dashboard.date': 'the date',
    'dashboard.time': 'the time',
    'dashboard.final': 'Finalize the appointment'
};
export default en_US;
