
const getQueryStringParams = (query) => {
  const encoded = new RegExp(/&amp;/g);
  let url = window.location.href;
  if (encoded.test(url)) {
    url = url.replaceAll(/&amp;/g, '&');
  }
  return (url.search(/[?]/g) !== -1)
    ? (url.split('?')[1])
      .split('&')
      .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
          return params;
        }, {}
      )[query]
    : undefined
};

export default getQueryStringParams;
