import React, { useState } from 'react';

import './AdminTemplate.css';
import AdminTemplateEditorForm from './AdminTemplateEditorForm';
import AdminTemplateEditorView from './AdminTemplateEditorView';
import axios from 'axios';

export default function AdminTemplateEditor({ templates, onCancel }) {
  const [status, setStatus] = useState('idle'); // ['idle', 'loading', 'error', 'success'
  const [error, setError] = useState(null); // [null, Error]
  const [{ template, field, value, details }, setTemplate] = useState({
    template: '',
    field: '',
    value: '',
    details: undefined,
  });

  const updateValue = (inputName, inputValue, prevForm) => {
    if (inputName === 'value') return inputValue;

    const isTemplateCompleted = inputName === 'template' || prevForm.template !== '';
    const isFieldCompleted = inputName === 'field' || prevForm.field !== '';

    if (!isTemplateCompleted || !isFieldCompleted) return '';

    const selectedTemplate = inputName === 'template' ? inputValue : prevForm.template;
    const selectedField = inputName === 'field' ? inputValue : prevForm.field;

    const { data: templateDetails} = templates.find((currTemplate) => {
      return currTemplate.value === selectedTemplate;
    });

    return (templateDetails && templateDetails[selectedField]) || '';
  }

  const onChange = (e) => {
   const { name, value: inputValue } = e.target;
   console.log(name, inputValue);
    setTemplate((prevForm) => {
      return {
        ...prevForm,
        [name]: inputValue,
        value: updateValue(name, inputValue, prevForm),
      }
    });
  };

  const getSelectedTemplate = () => {
    const selectedTemplate = templates.find((currTemplate) => currTemplate.value === template)?.data || {};

    if (field === 'detail' && details && value) {
      return {
        ...selectedTemplate,
        details: selectedTemplate.details.map((currDetail) => {
          if (currDetail.type === details) {
            return {
              ...currDetail,
              name: value || '',
            }
          }
          return currDetail;
        }),
      };
    }

    return {
      ...selectedTemplate,
      [field]: value,
    }
  };

  const verifyForm = () => {
    if (field === 'time') {
       if (!Number.isNaN(value) && Number.isInteger(Number.parseInt(value, 10))) return;
       throw new Error('La durée doit être un nombre entier');
    } else if (field === 'last_time' || field === 'start_time') {
      if (value.match(/^([0-1][0-9]|2[0-3])\.[0-5][0-9]$/)) return;
      throw new Error('L\'heure doit être au format HH.MM');
    }
  }

  const onSubmit = async () => {
    setStatus('loading');
    try {
      verifyForm();
      await axios.post(
        '/api/update_template',
        {
          template,
          new_value: (field === 'time') ? Number.parseInt(value, 10) : value,
          field: field,
          ...((field === 'details') ? { value: details } : {})
        },
      );
      setStatus('success');
      window.setTimeout(() => setStatus((prev) => prev === 'success' ? 'idle' : prev), 2000);
    } catch (err) {
      setError(err);
      setStatus('error');
    }
  }


  return (
    <div className="template-editor-overlay">
      <div className="template-editor-modal">
        <h1>
          Modifier un template
        </h1>
        {
          (status === 'error')
          && (
            <p className="error-message">
              {error.message}
            </p>
          )
        }
        {
          (status === 'success')
          && (
            <p className="success-message">
              Template mis à jour !
            </p>
          )
        }
        <div className="template-editor-content">
          <AdminTemplateEditorForm
            form={{ template, field, value, details }}
            onChange={onChange}
            templates={templates.map((template) => template.value)}
          />
          <AdminTemplateEditorView
            selectedTemplate={getSelectedTemplate()}
          />
        </div>
        <div className="template-editor-actions">
          <button
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSubmit}
            disabled={!template || !field || !value || (field === 'detail' && !details) || status === 'loading'}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  )
}
