import React from 'react';
import './style.css'

function AppointmentPreview({date, fullName, society, setSelectedAppointment}) {
  return (
    <div className='appointment-preview-container'
         onClick={() => setSelectedAppointment()}>
      <h1>
        {date}
      </h1>
      <h3>
        {fullName}
      </h3>
      <p>
        {society}
      </p>
    </div>
  )
}

export default AppointmentPreview;
