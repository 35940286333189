import React from 'react';
import './style.css'

function Header() {
  return (
    <div className='header-background'>
      <span className='header-company-name'>AYOMI</span>
    </div>
  )
}

export default Header;
