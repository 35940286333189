import axios from 'axios'

export const addLog = async (id, action, email = "") => {
    let full_url = window.location.href;
    let urlStat = full_url.split('?');
    const params = new URLSearchParams(full_url.split('?')[1]);
    let list = urlStat[0].split('/');
    let id_project = null;
    if (list[6] && list[6] !== 'fb' && list[6] !== 'gh' && list[6] !== 'client') {
        id_project = list[6];
    }
    try {
        await axios.request({
            method: 'POST',
            url: '/api/add_log',
            data: {
                "id": id,
                "action": action,
                "email": email || params.get('email'),
                "source": params.get('source'),
                "template": list[4],
                "user_calendly": list[5],
                "id_project": id_project,
            },
            ContentType: 'application/json; charset=utf-8',
        });
    } catch (err) {
        console.error(err);
    }
}