// import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';

import './views/calendar/index.css';
import './views/pop-up/style.css';
import './views/calendly/style.css'
import { IntlProvider } from 'react-intl';
import { lang } from './global';

import App from './App';
import * as serviceWorker from './serviceWorker';

// dotenv.config();

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser'); // eslint-disable-line global-require
//   worker.start();
// }

ReactDOM.render(<IntlProvider locale={'fr'} messages={lang}> <App /> </IntlProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
