import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Header from "../../components/Header";
import Dropdown from '../../components/Dropdown/Dropdown'
import Button from '../../components/Button'
import AppointmentsList from '../../components/AppointmentsList';
import TransferModalOneAppointment from '../../components/TransferModal/OneAppointment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.css'
import AppointmentModal from '../../components/AppointmentModal';
import TransferModalMultiplesAppointments
  from "../../components/TransferModal/MultiplesAppointments";
import AdminTemplates from './AdminTemplates/AdminTemplates';

function formatDateForBack(raw_date) {
  const year = raw_date.getFullYear().toString().padStart(4, '0');
  const month = (raw_date.getMonth() + 1).toString().padStart(2, '0');
  const day = raw_date.getDate().toString().padStart(2, '0');
  const hours = raw_date.getHours().toString().padStart(2, '0');
  const minutes = raw_date.getMinutes().toString().padStart(2, '0');
  const seconds = raw_date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function Admin() {
  const [templates, setTemplates] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [idToTransfer, setIdToTransfer] = useState('');
  const [selectRange, setSelectRange] = useState(false);
  const [showTransferMultipleModal,
    setShowTransferMultipleModal] = useState(false);

  useEffect(() => {
    async function fetchTemplates() {
      try {
        let response = await axios.request({
          method: 'GET',
          url: '/api/get-all-templates',
          ContentType: 'application/json',
        });
        setTemplates(response.data.templates);
      } catch (err) {
        console.log(`Error in fetchTemplates(): ${err}`);
      }
    }
    fetchTemplates();
  }, []);

  useEffect(() => {
    async function fetchTemplateUsers() {
      try {
        let response = await axios.request({
          method: 'GET',
          url: `/api/get-template-users/${selectedTemplate}`,
          ContentType: 'application/json',
        });
        setUsers(response.data.users);
      } catch (err) {
        console.log(`Error in fetchTemplateUsers(): ${err}`);
      }
    }
    setSelectedUser('');
    if (selectedTemplate !== '') {
      fetchTemplateUsers();
    }
  }, [selectedTemplate])

  useEffect(() => {
    async function fetchUserDateAppointments() {
      try {
        let response = await axios.request({
          method: 'POST',
          url: '/api/get-events-in-time',
          ContentType: 'application/json',
          data: {
            template: selectedTemplate,
            user: selectedUser,
            start_date: selectedStartDate,
            end_date: selectedEndDate
          }
        });
        setAppointments(response.data.appointments);
      } catch (err) {
        console.log(`Error in fetchUserDateAppointments(): ${err}`);
      }
    }

    if (selectedTemplate !== '' && selectedUser !== ''
        && selectedStartDate !== '' && selectedEndDate !== '') {
      fetchUserDateAppointments();
    }
  }, [selectedTemplate, selectedUser, selectedStartDate, selectedEndDate]);

  function selectDate(dateSelected) {
    if (selectRange) {
      setSelectedStartDate(formatDateForBack(dateSelected[0]));
      setSelectedEndDate(formatDateForBack(dateSelected[1]));
    } else {
      const endDate = new Date(dateSelected.getTime())
      setSelectedStartDate(formatDateForBack(dateSelected));
      endDate.setHours(23, 59, 59);
      setSelectedEndDate(formatDateForBack(endDate));
    }
  }

  async function transferAllAppointments(userToTransfer) {
    try {
      let response = await axios.request({
        method: 'POST',
        url: '/api/transfer-appointments-of-user-between-timestamps',
        ContentType: 'application/json',
        data: {
          template: selectedTemplate,
          from_user: selectedUser,
          to_user: userToTransfer,
          from_date: selectedStartDate,
          to_date: selectedEndDate
        }
      });
      setAppointments([]);
      setShowTransferMultipleModal(false);
    } catch (err) {
      console.log(`Error in transferAllAppointments(${userToTransfer}) : ${err}`);
    }
  }

  function removeAppointmentById(appointmentId) {
    const updatedAppointments = appointments.filter(
      appointment => appointment.id !== appointmentId)
    setAppointments(updatedAppointments);
  }

  return (
    <div className='admin-page-container'>
      <Header />
      <AdminTemplates templates={templates} />
      <div className='admin-body-container'>
        <div className='admin-body-column'>
          <div className='admin-calendar-handling-container'>
            <div className='admin-filter-container'>
              <Dropdown options={templates} onChange={setSelectedTemplate}
                        selected={selectedTemplate}
                        className='admin-filter-dropdown'/>
              <Dropdown options={users} onChange={setSelectedUser}
                        selected={selectedUser}
                        className='admin-filter-dropdown'/>
            </div>
            <Button onClick={() => setSelectRange(!selectRange)}>
              {`${(selectRange) ? 'Désactiver' : 'Activer'} la sélection multiple`}
            </Button>
          </div>
          <div className='admin-calendar-container'>
            <Calendar minDetail='month' onChange={(e) => selectDate(e)}
                      selectRange={selectRange}/>
          </div>
        </div>
        <div className='admin-body-column lighten-purple-background'>
          <div className='appointment-list-button-container'>
            <Button disabled={appointments.length === 0}
                    onClick={() => setShowTransferMultipleModal(true)}>
              Transférer tout ces rendez-vous
            </Button>
          </div>
          <AppointmentsList appointments={appointments}
                            setSelectedAppointment={setSelectedAppointment} />
        </div>
      </div>
      <AppointmentModal
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        setIdToTransfer={setIdToTransfer} />
      <TransferModalOneAppointment users={users} selectedUser={selectedUser}
                                   appointmentId={idToTransfer}
                                   setAppointmentId={setIdToTransfer}
                                   removeAppointmentById={removeAppointmentById}
      />
      <TransferModalMultiplesAppointments
        users={users} selectedUser={selectedUser}
        showModal={showTransferMultipleModal}
        setShowModal={setShowTransferMultipleModal}
        transferAllAppointments={transferAllAppointments} />
    </div>
  );
}

export default Admin;
