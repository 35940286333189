import React, {useState} from 'react';
import Button from '../../Button';
import { Modal } from 'react-bootstrap';
import Dropdown from '../../Dropdown/Dropdown';

function TransferModalMultiplesAppointments({users, selectedUser, showModal,
                                              setShowModal,
                                              transferAllAppointments}) {
  const [userToTransfer, setUserToTransfer] = useState('');

  const closeModal = () => setShowModal(false);
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        {`Transférer les rendez-vous sélectionnés de ${selectedUser}`}
      </Modal.Header>
      <Modal.Body>
        <Dropdown options={users} onChange={setUserToTransfer}
                  selected={userToTransfer} />
        <Button onClick={() => transferAllAppointments(userToTransfer)}
                disabled={userToTransfer === '' || userToTransfer === selectedUser}>
          Transférer
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default TransferModalMultiplesAppointments;
