import React from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './popupModif.css'
import 'pretty-checkbox'

export default class PopupModif extends React.Component  {
    constructor(props) {
        super(props)
        this.state = {
            template: "",
            user: "",
            templateInfos: {},
            checkboxes: {},
            allSlots: [],
            exceptBlocked : []
        }
        this.close = this.close.bind(this)
        this.initBoxes = this.initBoxes.bind(this)
        this.convertMinute = this.convertMinute.bind(this)
        this.send = this.send.bind(this)
        this.checkInitBoxes = this.checkInitBoxes.bind(this)
        this.dispSelTimes = this.dispSelTimes.bind(this)
	this.exceptBlockedDelete = this.exceptBlockedDelete.bind(this)
    };

    componentDidMount(){
        this.initBoxes()
    }

    componentDidUpdate(prev) {
        if (this.props.user !== '' && prev.user === "")
            this.checkInitBoxes()
    }

    initBoxes() {
        let full_url = window.location.href
        let list = full_url.split('/')

        axios.request({
            method: 'POST',
            url: '/api/get_template1',
            data: {
                "url" : list[5]
            },
            headers:{
                'Content-Type' : "application/json"
            }
        }).then((response) => {
            var buff = {"last_time" : response['data']['last_time'], "start_time" : response["data"]["start_time"], "template" : response["data"]["template"], "time": response["data"]["time"]}

            if (response["data"]) {
                this.setState({
                    templateInfos: buff
                })
            }
        })
    }

    close() {
        this.props.closePop()
        this.checkInitBoxes()
        document.getElementById("debutSelectDate").value = ""
        document.getElementById("finSelectDate").value = ""
    }

    send() {
        let allSlots = []
        let debutSelectDate = document.getElementById("debutSelectDate").value
        let debutSelectHours = document.getElementById("debutSelectHours").value
        let finSelectDate = document.getElementById("finSelectDate").value
        let finSelectHours = document.getElementById("finSelectHours").value

        for (var key in this.state.checkboxes) {
            if (this.state.checkboxes[key]) {
                allSlots.push(key)
            }
        }
        axios.request({
            method: 'POST',
            url: '/api/update_gi',
            data: {
                "user" : this.props.user,
                "field" : "weeklyBlocked",
                "new_value" : allSlots
            },
            headers:{
                'Content-Type' : "application/json"
            }
        }).then((response) => {
            let error = 0
            if (debutSelectDate && finSelectDate) {
                let debut = debutSelectDate + " " + debutSelectHours
                let fin = finSelectDate + " " + finSelectHours
                let listDebut = debutSelectDate.split('-')
                let listfin = finSelectDate.split('-')

                if (parseInt(listDebut[0], 10) > parseInt(listfin[0], 10))
                    error += 1
                if (parseInt(listDebut[0], 10) === parseInt(listfin[0], 10)) {
                    if (parseInt(listDebut[1], 10) > parseInt(listfin[1], 10))
                        error += 1
                    if (parseInt(listDebut[1], 10) === parseInt(listfin[1], 10)) {
                        if (parseInt(listDebut[2], 10) > parseInt(listfin[2], 10))
                            error += 1
                        if (parseInt(listDebut[2], 10) === parseInt(listfin[2], 10)) {
                            let listDebutHour = debutSelectHours.split('h')
                            let listFinHour = finSelectHours.split('h')
                            if (parseInt(listDebutHour[0], 10) > parseInt(listFinHour[0], 10))
                                error += 1
                            if (parseInt(listDebutHour[0], 10) === parseInt(listFinHour[0], 10)) {
                                if (parseInt(listDebutHour[1], 10) > parseInt(listFinHour[1], 10))
                                    error += 1
                            }
                        }
                    }
                }

                if (error === 0) {
                    axios.request({
                        method: 'POST',
                        url: '/api/update_gi',
                        data: {
                            "user" : this.props.user,
                            "field" : "exceptBlocked",
                            "new_value" : {"start" : debut, "end" : fin}
                        },
                        headers:{
                            'Content-Type' : "application/json"
                        }
                    })
                }
            }
            this.props.closePop()
            if (error === 0) {
                if (response['data']['_shards']['failed'] === 0) {
                    confirmAlert({
                        title: 'Créneaux mis à jour avec succès !',
                        buttons: [
                        {
                            label: 'Ok'
                        }
                        ]
                    })
                }
                else {
                    confirmAlert({
                        title: 'Echec de la mise à jour des créneaux',
                        message: "Contactez l'administrateur si le problème persiste",
                        buttons: [
                        {
                            label: 'Ok'
                        }
                        ]
                    })
                }
            } else {
                confirmAlert({
                    title: 'Les dates choisies sont incorrectes.',
                    buttons: [
                    {
                        label: 'Ok'
                    }
                    ]
                })
            }
        })
    }

    convertMinute(time) {
        let listTime = time.split('.')
        let timeMinute = parseInt(listTime[0], 10) * 60

        if (listTime.length === 2)
            timeMinute += parseInt(listTime[1], 10)
        return timeMinute
    }

    updateBox(i, day) {
        let objBoxes = this.state.checkboxes

        objBoxes[day + i['slot']] = !objBoxes[day + i['slot']]
        this.setState({
            checkboxes: objBoxes
        })
    }

    checkInitBoxes() {
        if (this.state.templateInfos['start_time'] === undefined) {
            let full_url = window.location.href
            let list = full_url.split('/')

            axios.request({
                method: 'POST',
                url: '/api/get_template1',
                data: {
                    "url" : list[5]
                },
                headers:{
                    'Content-Type' : "application/json"
                }
            }).then((response) => {
                var buff = {"last_time" : response['data']['last_time'], "start_time" : response["data"]["start_time"], "template" : response["data"]["template"], "time": response["data"]["time"]}

                if (response["data"]) {
                    this.setState({
                        templateInfos: buff
                    })
                }
                axios.request({
                    method: 'POST',
                    url: '/api/get_blocked_slots',
                    data: {
                        "user" : this.props.user
                    },
                    headers:{
                        'Content-Type' : "application/json"
                    }
                }).then((response) => {
                    if (response['data'] === "No such user" || response['data'] === "No blocked slots defined")
                        return
                    else {
                        let allSlots = []
                        let startTime = this.state.templateInfos['start_time']
                        let lastTime = this.state.templateInfos['last_time']
                        let time = parseInt(this.state.templateInfos['time'], 10)
                        let hour
                        let min
                        let listHour
                        let dispTime
                        let objBoxes = {}
                
                        startTime = this.convertMinute(startTime.toString())
                        lastTime = this.convertMinute(lastTime.toString())
                
                        while (startTime <= lastTime) {
                            hour = (startTime / 60).toString()
                            min = (startTime % 60).toString()
                            listHour = hour.split('.')
                            hour = listHour[0]
                            if (min.length === 1)
                                min = '0' + min
                            dispTime = hour + 'h' + min
                            allSlots.push({"slot" : dispTime})
                
                            objBoxes['Mon ' + dispTime] = false
                            objBoxes['Tue ' + dispTime] = false
                            objBoxes['Wed ' + dispTime] = false
                            objBoxes['Thu ' + dispTime] = false
                            objBoxes['Fri ' + dispTime] = false
                            startTime += time
                        }
                        this.setState({
                            allSlots: allSlots
                        })
                        response['data']['weeklyBlocked'].forEach((element) => {
                            objBoxes[element] = true
                        });
                        this.setState({
                            checkboxes: objBoxes
                        })
                        this.setState({
                            exceptBlocked : response['data']['exceptBlocked']
                        })
                    }
                })
            })
        }
    }

    dispSelTimes() {
        let listSlots = this.state.allSlots.map((i)=> {
            return(
                <option>{i['slot']}</option>
            )
        })
        return listSlots
    }

    dispBoxes() {
        let listSlots = this.state.allSlots.map((i)=> {
            return(
            <tr className="tdAndth">
                <th className="tdAndth">{i['slot']}</th>
                <th className="tdAndth">
                    <div class="pretty p-icon p-curve">
                        <input type="checkbox" onChange={(e) => this.updateBox(i, "Mon ")} id={"Mon " + i['slot']} checked={this.state.checkboxes["Mon " + i['slot']]}/>
                        <div class="state p-danger">
                            <i class="icon mdi mdi-close"></i>
                            <label></label>
                        </div>
                    </div>
                </th>
                <th className="tdAndth">
                    <div class="pretty p-icon p-curve">
                        <input type="checkbox" onChange={(e) => this.updateBox(i, "Tue ")} id={"Tue " + i['slot']} checked={this.state.checkboxes["Tue " + i['slot']]}/>
                        <div class="state p-danger">
                            <i class="icon mdi mdi-close"></i>
                            <label></label>
                        </div>
                    </div>
                </th>
                <th className="tdAndth">
                    <div class="pretty p-icon p-curve">
                        <input type="checkbox" onChange={(e) => this.updateBox(i, "Wed ")} id={"Wed " + i['slot']} checked={this.state.checkboxes["Wed " + i['slot']]}/>
                        <div class="state p-danger">
                            <i class="icon mdi mdi-close"></i>
                            <label></label>
                        </div>
                    </div>
                </th>
                <th className="tdAndth">
                    <div class="pretty p-icon p-curve">
                        <input type="checkbox" onChange={(e) => this.updateBox(i, "Thu ")} id={"Thu " + i['slot']} checked={this.state.checkboxes["Thu " + i['slot']]}/>
                        <div class="state p-danger">
                            <i class="icon mdi mdi-close"></i>
                            <label></label>
                        </div>
                    </div>
                </th>
                <th className="tdAndth">
                    <div class="pretty p-icon p-curve">
                        <input type="checkbox" onChange={(e) => this.updateBox(i, "Fri ")} id={"Fri " + i['slot']} checked={this.state.checkboxes["Fri " + i['slot']]}/>
                        <div class="state p-danger">
                            <i class="icon mdi mdi-close"></i>
                            <label></label>
                        </div>
                    </div>
                </th>
            </tr>
            )
        })
        return listSlots
    }


    editExceptBlockedSave(data)
    {
        axios.request({
            method: 'POST',
            url: '/api/blocked_slots',
            data: {"user":this.props.user,"exceptBlocked":data},
            headers:{
                'Content-Type' : "application/json"
            }
        }).then((response) => {
            console.log(response)
        })
    }

    exceptBlockedDelete(e) 
    {
        let exceptBlocked = this.state.exceptBlocked

        exceptBlocked = exceptBlocked.filter((a) => { 
           return (a["start"] != e["start"] && a["end"]  != e["end"]) 
        })
        this.setState({exceptBlocked: exceptBlocked})
        this.editExceptBlockedSave(exceptBlocked)
    }
    render() {

        let i = -1
        const exceptBlocked = this.state.exceptBlocked.map(data => {
            i++
            return(
                <tr key={i} className="tdAndth">
                    <th className="tdAndth">{data["start"]}</th>
                    <th className="tdAndth">{data["end"]}</th>
                    <th className="tdAndth">
                        <button onClick={(e) => this.exceptBlockedDelete(data)} >Delete</button>
                    </th>
                </tr>
            )
            
            
        })
        return(
            <div hidden = {this.props.hidden}>
                <div className="modal-wrap">
                    <div className="scrollit">
                    <h2>Gestion des créneaux de rdvs du user {this.props.user}</h2>
                    <b>Bloquer des créneaux hebdomadairement:</b>
                    <p><br/></p>
                        <table>
                            <tr className="tdAndth">
                                <th className="tdAndth"></th>
                                <th className="tdAndth">Lundi</th>
                                <th className="tdAndth">Mardi</th>
                                <th className="tdAndth" >Mercredi</th>
                                <th className="tdAndth" >Jeudi</th>
                                <th className="tdAndth">Vendredi</th>
                            </tr>
                            {this.dispBoxes()}
                        </table>
                    <br/>
                    <b>Bloquer des créneaux exceptionnellement:</b>
                    <br/>
                    <table>
                            <tr className="tdAndth">
                                <th className="tdAndth">Debut</th>
                                <th className="tdAndth">Fin</th>
                                <th className="tdAndth">Option</th>
                            </tr>
                            {exceptBlocked}
                    </table>
                    <p>Début: <input type="date" id="debutSelectDate"/>
                    <select id="debutSelectHours"> 
                        {this.dispSelTimes()}
                    </select>
                    </p>
                    <p>Fin: <input type="date" id="finSelectDate"/>
                    <select id="finSelectHours"> 
                        {this.dispSelTimes()}
                    </select>
                    </p>
                    <br/>
                    <div>
                        <button onClick={(e) => this.close()}>ANNULER</button><button onClick={() => {this.send()}}>VALIDER</button>
                    </div>
                 </div>
                </div>
            </div>
        )
    }
}
