/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/jsx-pascal-case */
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import bowser from 'bowser';
import React, { Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Scroll from 'react-scroll';

import Loading from "../../components/Loading";
import { addLog } from '../../utils/log';
import TimezoneDropdown from '../../components/TimezoneDropdown';
import { timeToDisplay } from '../../utils/timezone/displayOffsetTime';
import { getClientTimeZoneOffset } from '../../utils/timezone/getClientTimeZoneOffset';
import getTimezoneOffsetFromName from '../../utils/timezone/getTimezoneOffsetFromName';
import Calendar from '../../views/calendar/Calendar';
import Pop_Loading from '../../views/pop-up/pop_loading';
import BrowserAlert from '../browserUpdate/browserUpdate';

const PopUp = React.lazy(() => import('../pop-up/pop-up'));
const PopConfirmation = React.lazy(() => import('../pop-up/pop-confirmation'));
const Pop_Error = React.lazy(() => import('../../views/pop-up/pop_error'));
const PopErrorBooked = React.lazy(() => import('../pop-up/pop-error-booked'));
const PopupLP = React.lazy(() => import('../pop-up/pop-up-lp'));
const FbVid = React.lazy(() => import('./fbVid'));

export default class calendly extends React.Component {
    constructor(props) {
        super(props)
        this.onselect = this.onSelect.bind(this);
        this.onSelect_hours = this.onSelect_hours.bind(this);
        this.build_rdv = this.build_rdv.bind(this)
        this.url_parsing_and_adapt = this.url_parsing_and_adapt.bind(this)
        this.manage_hours_of_rdv = this.manage_hours_of_rdv.bind(this)
        this.host = "/api/"
        this.togglePopup = this.togglePopup.bind(this)
        this.togglePopConf = this.togglePopConf.bind(this)
        this.togglePopErr = this.togglePopErr.bind(this)
        this.togglePopErrBooked = this.togglePopErrBooked.bind(this)
        this.togglePopupLP = this.togglePopupLP.bind(this)
        this.toggleFb = this.toggleFb.bind(this)
        this.checkWindowSize = this.checkWindowSize.bind(this)
        this.setIdCookie = this.setIdCookie.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.startTimer = this.startTimer.bind(this)
        this.getEndTime = this.getEndTime.bind(this)
        this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this)
        const date = new Date();
        this.state = {
            popupLP: true,
            popup: true,
            pupop: true,
            poperr: true,
            popLoading: true,
            popAlreadyBooked: true,
            fromFb: true,
            arrow: false,
            inputTime: [],
            date: [],
            time: {},
            template: {},
            special_fields: {},
            details: {},
            timeAvailable: [],
            user: "",
            startTime: "",
            endTime: "",
            dateAvailable: [],
            chooseDate: "",
            currentMonth: date.getMonth(),
            currentYear: date.getFullYear(),
            isFetchingTime: false,
            isV2: true,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || '',
        }
        addLog(6, "Arrived");
    }

    getCookie(cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setIdCookie(cookie_name) {
        const cookie = this.getCookie(cookie_name)
        if (cookie !== "")
            return
        const idPerson = Math.random().toString(36).substr(2)
        const endOfToday = new Date();
        endOfToday.setHours(23, 59, 59, 999);
        const expires = "expires=" + endOfToday.toUTCString();
        document.cookie = cookie_name + "=" + idPerson + ";" + expires + ";path=/";
    }

    getEndTime() {
        let full_url = window.location.href
        let urlStat = full_url.split('?')
        var timerEnd;

        timerEnd = Date.now();
        var timeValid = new Date(timerEnd).toISOString()
        this.setState({
            endTime: timeValid
        })
        axios.request({
            method: 'POST',
            url: '/api/update_stat',
            data: {
                "url": urlStat[0],
                "startTime": this.state.startTime,
                "endTime": this.state.endTime,
                "cookie": this.getCookie("idPerson")
            },
            ContentType: "application/json; charset=utf-8"
        })
    }

    componentDidMount() {
        window.addEventListener("beforeunload", (ev) => {
            this.getEndTime()
        });
        this.setIdCookie("idPerson")
        this.url_parsing_and_adapt()
        this.startTimer();
    }

    startTimer() {
        var timerStart;

        timerStart = Date.now();
        var timeValid = new Date(timerStart).toISOString()
        this.setState({
            startTime: timeValid
        })
    }

    checkWindowSize() {
        if (window.matchMedia("(min-width: 768px)").matches) {
            this.setState({
                arrow: true
            })
        }
    }

    url_parsing_and_adapt() {
        let full_url = window.location.href
        let urlStat = full_url.split('?')
        this.checkWindowSize()


        axios.request({
            method: 'POST',
            url: '/api/add_stat',
            data: {
                "url": urlStat[0],
                "type": "visite",
                "cookie": this.getCookie("idPerson")
            },
            ContentType: "application/json; charset=utf-8"

        })

        let list = urlStat[0].split('/')
        if (list.length !== 6 && list.length !== 7 && list.length !== 8) {
            window.location.href = "https://salesapi.ayomi.fr/calendar/next_available_appointment?source=racine"
        }

        let team = list[4]
        let user = list[5]

        this.setState({ user: user })
        let error = 0
        let i = 0

        if (list[7] && team === "sales") {
            this.togglePopErr()
        }
        if (list.length === 8) {
            if (list[7] !== "fb" && list[7] !== "client" && list[7] !== ''
                && team === "ri") {
                this.togglePopErr()
            }
        }
        if ((list[6] === 'fb' || list[6] === 'gh' || list[6] === 'client') && this.state.fromFb) {
            this.toggleFb()
        }
        if (list[6] && list[6] !== 'fb' && list[6] !== 'gh' && list[6] !== 'client') {
            this.setState({ id: list[6] })
            axios.request({
                method: 'POST',
                url: '/api/get_details',
                data: {
                    "id": list[6]
                },
                ContentType: "application/json; charset=utf-8"

            }).then((response) => {
                if (response.data['hits']['total']['value'] === 0) {
                    this.togglePopErr()
                    return (null)
                }
                this.setState({ details: response['data']['hits']['hits'][0]['_source'] })
                let full_url = window.location.href;
                if (new RegExp(/&amp;/).test(full_url)) {
                    full_url = full_url.replaceAll(/&amp;/, '&');
                }
                const params = new URLSearchParams(full_url.split('?')[1]);;
                const source = params.get('source');
                const email = params.get('email');
                if (team === 'ri' && source === 'mail' && email) {
                    const trackUser = () => {
                        axios.request({
                            method: 'POST',
                            url: '/api/track_ri_lead',
                            data: {
                                "email": email,
                                "id_project": list[6],
                            },
                            headers: {
                                'Content-Type': "application/json"
                            }
                        });
                    };
                    const trackUserMove = () => {
                        trackUser();
                        window.removeEventListener('click', trackUserMove);
                    }
                    window.addEventListener('click', trackUserMove);
                }
            })

        }

        axios.request({
            method: 'POST',
            url: '/api/get_template1',
            data: {
                "url": team
            },
            headers: {
                'Content-Type': "application/json"
            }
        }).then((response) => {
            let dataResponse = response['data']
            if (dataResponse['Error']) {
                this.togglePopErr()
                return (null)
            }
            if (!dataResponse['users'].includes(user)) {
                this.togglePopErr()
            }
            let date = new Date()

            if (dataResponse) {
                this.setState({ special_fields: dataResponse['special_fields'] })
                this.setState({ template: dataResponse })
                this.fetchFirstAvailableMonth(user, team);
            }
        })
            .catch((error) => {
                this.togglePopErr()
                console.log(error);
            })
    }

    async fetchFirstAvailableMonth(user, team) {
        let isSlotAvailableThisMonth = false;
        const initialDate = new Date();

        while (!isSlotAvailableThisMonth) {
            let response = await axios.request({
                method: 'POST',
                url: '/api/get_user_slots',
                data: {
                    "user": user,
                    "template": team,
                    "date": initialDate.getFullYear() + "-" + this.pad(initialDate.getMonth() + 1, 2),
                },
                headers: {
                    'Content-Type': "application/json"
                }
            });
            const dateAvailable = response["data"]["data"];
            if (Object.keys(dateAvailable).length !== 0) {
                isSlotAvailableThisMonth = true;
                this.setState({
                    popLoading: false, dateAvailable,
                    currentMonth: initialDate.getMonth(),
                    currentYear: initialDate.getFullYear()
                });
            } else {
                const currentMonth = initialDate.getMonth();

                if (currentMonth === 11) {
                    initialDate.setMonth(0);
                    initialDate.setFullYear(initialDate.getFullYear() + 1);
                } else {
                    initialDate.setMonth(initialDate.getMonth() + 1);
                }
            }
        }

    }

    pad(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    build_rdv() {
        let date = ""
        if (this.state.date.length === 3)
            date = this.pad(this.state.date[0], 2) + "/" + this.pad((this.state.date[1] + 1), 2) + "/" + this.state.date[2]
        else
            date = ""

        let time = this.state.time["hours"] === undefined ? "" : this.pad(this.state.time["hours"], 2) + "h" + this.pad(this.state.time["minutes"], 2)
        var str = date + " " + time
        return (str)
    }

    onSelect(date) {
        addLog(1, "Select date");
        window.gtag('event', 'calendar_select_date');
        if (this.state.isV2 && date[0] != 0) {
            this.setState({ date: date, isFetchingTime: true })
        }
        let scroll = Scroll.animateScroll;
        axios.request({
            method: 'POST',
            url: '/api/get_user_slots',
            data: {
                "template": this.state.template.template,
                "user": this.state.user,
                "date": date[2] + "-" + this.pad(date[1] + 1, 2)
            },
            ContentType: "application/json; charset=utf-8"
        }).then((response) => {
            const monthAvailability = response["data"]["data"];
            this.setState({
                currentMonth: date[1], currentYear: date[2],
                dateAvailable: monthAvailability
            });
            this.setState({ chooseDate: "" })
            if (date[0] != 0) {
                (this.state.isV2)
                    ? this.setState({ isFetchingTime: false })
                    : this.setState({ date: date })
                this.setState({ timeAvailable: monthAvailability[parseInt(date[0])] })
            }
            if (!this.state.isV2) {
                scroll.scrollToBottom()
            }
        })
    }

    onSelect_hours(time) {
        addLog(2, "Select Time");
        window.gtag('event', 'calendar_select_time');
        this.setState({ time: time })
        this.togglePopup()
    }

    convertMinuteToHour(n) {
        let num = n;
        let hours = (num / 60);
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        return {
            "hours": rhours,
            "minutes": rminutes
        }
    }

    manage_hours_of_rdv() {
        if (this.state.isFetchingTime) {
            return <Loading />;
        }
        if (this.state.timeAvailable.length === 0) {
            return (
                <FormattedMessage id="calendly.noTimeAvailable">
                    {
                        (message) => (
                            <p>
                                {message}
                            </p>
                        )
                    }
                </FormattedMessage>
            );
        }
        const timezoneOffset = getClientTimeZoneOffset(this.state.timeZone);
        let listInput = this.state.timeAvailable.map((i) => {
            return (
                <button
                    key={i}
                    onClick={(e) => { this.onSelect_hours(i) }}
                    className={this.state.time === i ? "button active-button" : "button default-button"}>
                    {timeToDisplay(i, timezoneOffset)}
                </button>
            );
        })
        return listInput
    }


    togglePopup() {
        this.setState({
            popup: !this.state.popup
        });
    }

    togglePopConf() {
        this.setState({
            pupop: !this.state.pupop
        });
    }

    togglePopErr() {
        this.setState({
            poperr: !this.state.poperr
        });
    }

    togglePopErrBooked() {
        this.setState({
            popAlreadyBooked: !this.state.popAlreadyBooked
        });
        this.url_parsing_and_adapt();
    }

    togglePopupLP() {
        this.setState({
            popupLP: !this.state.popupLP
        });
    }

    toggleFb() {
        this.setState({
            fromFb: false
        })
    }

    handleTimeZoneChange(value) {
        this.setState({
            timeZone: value
        })
    }

    build_page() {
        let full_url = window.location.href
        let list = full_url.split('/')
        if (this.state.fromFb) {
            let title = this.state.template["title"]
            return (
                <div className="col-12 col-md-2 banner">
                    <header className="title">{title}</header>
                    <div className="hint">
                        <FormattedMessage id="dashboard.choose">
                            {
                                (message) => (
                                    <p className="hint_title">{message}</p>
                                )
                            }
                        </FormattedMessage>
                        <p>
                            <span className="hint_number"> 1 </span>
                            <FormattedMessage id="dashboard.date">
                                {
                                    (message) => (<span className="hint_text">{message}</span>)
                                }
                            </FormattedMessage>

                        </p>
                        <p>
                            <span className="hint_number"> 2 </span>
                            <FormattedMessage id="dashboard.time">
                                {
                                    (message) => (<span className="hint_text">{message}</span>)
                                }
                            </FormattedMessage>

                        </p>
                        <p>
                            <span className="hint_number"> 3 </span>
                            <FormattedMessage id="dashboard.final">
                                {
                                    (message) => (<span className="hint_text">{message}</span>)
                                }
                            </FormattedMessage>

                        </p>
                    </div>
                </div>
            )
        }
    }

    render() {
        const { currentMonth, currentYear } = this.state;
        let result = bowser.getParser(window.navigator.userAgent);
        if ((result.parsedResult.browser.name === "Internet Explorer") || (result.parsedResult.browser.name === 'Chrome' && parseFloat(result.parsedResult.browser.version) <= 46)
            || (result.parsedResult.browser.name === 'Opera' && parseFloat(result.parsedResult.browser.version) <= 33) || (result.parsedResult.browser.name === 'Firefox' && parseFloat(result.parsedResult.browser.version) <= 42))
            return (<BrowserAlert />)
        else {
            return (
                <div className={`${this.state.isV2 ? 'v2' : ''}`}>
                    <div className="container-fluid">
                        <div className="row stability">
                            {this.build_page()}
                            {
                                (!this.state.fromFb)
                                && (
                                    <Suspense fallback={<p>Chargement...</p>}>
                                        <FbVid hidden={this.state.fromFb} style={(this.state.date.length !== 0) ? 'hide' : ''} />
                                    </Suspense>
                                )
                            }
                            {
                                (this.state.isV2 && (this.state.date.length > 0))
                                && (
                                    <div className="v2-container">
                                        <header className="v2-header">
                                            <button
                                                onClick={() => this.setState({ date: [] })}
                                                aria-label="Previous"
                                            >
                                                <FontAwesomeIcon icon={faArrowLeft} />
                                            </button>
                                            <div>
                                                <p className="rdv">
                                                    {new Date(this.state.date[2], this.state.date[1], this.state.date[0]).toLocaleDateString('fr-FR', {
                                                        weekday: 'long', year: 'numeric', month: 'long', day: '2-digit',
                                                    })}
                                                </p>
                                                <FormattedMessage id="pop_confirmation.timezone">
                                                    {
                                                        (message) => (
                                                            <p className="timezone"> {message} : {this.state.timeZone}</p>
                                                        )
                                                    }
                                                </FormattedMessage>
                                            </div>
                                        </header>
                                    </div>
                                )
                            }
                            <div className="col-12 col-md-10">
                                <div className="row">
                                    <div className={`col-12 col-lg-6 calendar-landing ${(this.state.date.length !== 0) ? 'hide' : ''}`}>
                                        <FormattedMessage id="calendly.chooseDay">
                                            {(messages) => <header className="go-pick"> {messages}</header>}
                                        </FormattedMessage>
                                        <div>
                                            <Calendar dateAvailable={Object.keys(this.state.dateAvailable).map(x => parseInt(x, 10))} user={this.state.user} template={this.state.template} onSelect={this.onselect} currentMonth={currentMonth} currentYear={currentYear} />
                                            <TimezoneDropdown
                                                FormattedMessage={FormattedMessage}
                                                value={this.state.timeZone}
                                                onChange={this.handleTimeZoneChange}
                                                disabled={(getTimezoneOffsetFromName(this.state.timeZone) === null)}
                                            />
                                        </div>
                                    </div>
                                    <div className={`col-12 col-lg-6 schedule-landing ${(this.state.date.length === 0) ? 'hide' : ''}`}>
                                        <FormattedMessage id="calendly.chooseTime">
                                            {(messages) => <header className="go-pick"> {messages}</header>}
                                        </FormattedMessage>
                                        <FormattedMessage id="calendly.callDuration">
                                            {(messages) => <p className="call">{messages} : {this.state.template.time || 15} minutes</p>}
                                        </FormattedMessage>
                                        <div className="button-scroll-area">
                                            {this.state.date.length > 0 ? this.manage_hours_of_rdv() :
                                                <FormattedMessage id="calendly.chooseDate">
                                                    {(messages) => <p>{messages}</p>}
                                                </FormattedMessage>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pop_Loading hidden={!this.state.popLoading} />
                    {
                        (!this.state.popup)
                        && (
                            <Suspense fallback={<p>Chargement...</p>}>
                                <PopUp
                                    toggle={this.togglePopup}
                                    togglePC={this.togglePopConf}
                                    togglePopUpAlreadyBooked={this.togglePopErrBooked}
                                    id={this.state.id}
                                    user={this.state.user}
                                    date={this.state.date}
                                    time={this.state.time}
                                    template={this.state.template}
                                    special_fields={this.state.special_fields}
                                    hidden={this.state.popup}
                                    timeZone={this.state.timeZone}
                                />
                            </Suspense>
                        )
                    }
                    {
                        (!this.state.pupop)
                        && (
                            <Suspense fallback={<p>Chargement...</p>}>
                                <PopConfirmation
                                    date={this.state.date}
                                    time={this.state.time}
                                    hidden={this.state.pupop}
                                    duration={this.state.template.time}
                                    type={this.state.template.template}
                                    timeZone={this.state.timeZone}
                                />
                            </Suspense>
                        )
                    }
                    {
                        (!this.state.poperr)
                        && (
                            <Suspense fallback={<p>Chargement...</p>}>
                                <Pop_Error toggleErr={this.togglePopErr} hidden={this.state.poperr} />
                            </Suspense>
                        )
                    }
                    {
                        (!this.state.popAlreadyBooked)
                        && (
                            <Suspense fallback={<p>Chargement...</p>}>
                                <PopErrorBooked
                                    hidden={this.state.popAlreadyBooked}
                                    togglePopUpAlreadyBooked={this.togglePopErrBooked}
                                />
                            </Suspense>
                        )
                    }
                    {
                        (!this.state.popupLP && this.state.poperr)
                        && (
                            <Suspense fallback={<p>Chargement...</p>}>
                                <PopupLP
                                    hidden={this.state.popupLP && !this.state.poperr}
                                    togglePopupLP={this.togglePopupLP}
                                />
                            </Suspense>
                        )
                    }
                </div>
            )
        }
    }
}
