import React from 'react';

export default function AdminTemplateEditorForm({ form, onChange, templates }) {
  return (
    <form className="template-editor-form">
      <label htmlFor="template" className="template-editor-label">
        Template:
        <select
          name="template"
          id="template"
          value={form.template}
          onChange={onChange}
        >
          <option key="empty-template" value="" disabled> Select a template </option>
          {
            templates.map((template) => (
              <option key={template} value={template}>{template}</option>
            ))
          }
        </select>
      </label>
      <label htmlFor="field" className="template-editor-label">
        Champs
        <select
          name="field"
          id="field"
          value={form.field}
          onChange={onChange}
        >
          <option key="empty-field" value="" disabled> Select a field </option>
          <option value="time">Durée</option>
          <option value="start_time">Heure de début</option>
          <option value="last_time">Heure de fin</option>
          <option value="title">Titre</option>
          <option value="preface">Préface</option>
          <option value="details">Detail</option>
        </select>
      </label>
      {
        (form.field === 'details') && (
          <label
            htmlFor="details"
            className="template-editor-label"
          >
            Type de champ
            <select
              name="details"
              value={form.details || ''}
              onChange={onChange}
            >
              <option value="" disabled>Sélectionnez une option</option>
              <option value="text">Texte court</option>
              <option value="text_align">Texte long</option>
            </select>
          </label>
        )
      }
      <label
        htmlFor="value"
        className="template-editor-label"
      >
        Valeur
        <textarea
          name="value"
          id="value"
          value={form.value}
          onChange={onChange}
        />
        {
          ((form.field === 'last_time') || (form.field === 'start_time'))
          && (
            <p className="template-editor-hint">
              L'heure doit être au format HH.MM
            </p>
          )
        }
      </label>
    </form>
  )
}
