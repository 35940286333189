import calendly from '../../views/calendly/calendly';
import calendrierUser from '../../views/calendrierUser/calendrierUser'
import admin from '../../views/admin'

let routes =  {
    "/calendrier" : calendrierUser,
    "/" : calendly,
    '/admin': admin,
};


export default routes

