import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function TimezoneDropdownSearchbar({ children }) {
  const [query, setQuery] = useState('');
  return (
    <>
      <div className="timezone__searchbar">
        <FontAwesomeIcon
          icon={faSearch}
          className="timezone__icon"
        />
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="timezone__input"
          placeholder="Europe/Paris"
        />
        {
          (query) && (
            <button
              type="button"
              onClick={() => setQuery('')}
              className="timezone__clear"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )
        }
      </div>
      {children(query)}
    </>
  )
}
